import React, { useMemo } from 'react';

import { RouteComponentProps } from '@reach/router';
import Layout from 'src/components/layout/layout';
import { getClientProps } from 'src/components/organisations/client-props';
import OrganisationProfile from 'src/components/organisations/organisation-profile/organisation-profile';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';
import IsHighSchoolState from 'src/components/util-components/is-high-school-state';
import Redirect from 'src/components/util-components/redirect';
import ROUTES from 'src/utils/routes';

type OrganisationProfilePageProps = { orgId?: string } & RouteComponentProps;

export const OrganisationProfilePage: React.FC<OrganisationProfilePageProps> = ({ orgId }) => {
  const clientProps = useMemo(() => getClientProps()?.profilePage ?? {}, []);
  return (
    <Layout>
      <SEO title="Organisation Profile" />
      <PrivateRoute>
        <IsHighSchoolState>
          <IsHighSchoolState.Render ifTrue>
            <Redirect to={ROUTES.NOT_FOUND} />
          </IsHighSchoolState.Render>
          <IsHighSchoolState.Render ifFalse>
            <OrganisationProfile {...clientProps} orgId={orgId} />
          </IsHighSchoolState.Render>
        </IsHighSchoolState>
      </PrivateRoute>
    </Layout>
  );
};

export default OrganisationProfilePage;
