import React from 'react';

import { useLocation, WindowLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from 'src/components/breadcrumbs/breadcrumbs';
import ErrorPanel from 'src/components/error-panel/error-panel';
import Spinner from 'src/components/spinner/spinner';
import Redirect from 'src/components/util-components/redirect';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { FindOrganisations_findOrganisations_results } from 'src/graphql-types/saas/FindOrganisations';
import ROUTES from 'src/utils/routes';

import { defaultConfig } from './config';
import OrganisationProfileHeader from './header/organisation-profile-header';
import {
  useHasOrgAdminAccess,
  useOrganisationProfile,
  useOrgProfileHeader,
  useOrgProfileTabNavigation,
  useOrgProfileTabs,
  useRelationalViewingContext,
} from './hooks';
import OrganisationProfileTabs from './tabs/organisation-profile-tabs';
import { OrganisationProfileProps } from './types';
import { getTabLabel } from './utils';

const OrganisationProfile = ({
  orgId,
  tabsConfig = defaultConfig.tabsConfig,
  headerConfig = defaultConfig.headerConfig,
  allowedTypes = defaultConfig.allowedTypes,
}: OrganisationProfileProps) => {
  const { t } = useTranslation();
  const page = useLocation() as WindowLocation<FindOrganisations_findOrganisations_results>;
  const prefetchedOrg = page.state?.organisationId && page.state.organisationId === orgId ? page.state : undefined;
  const {
    data: orgData,
    loading,
    error: errorOrg,
  } = useOrganisationProfile(orgId, {
    shouldSkip: !!prefetchedOrg || !orgId,
    redirectOnNoData: true,
  });
  const org = prefetchedOrg ?? orgData?.getOrganisationById ?? undefined;
  const { error: errorViewingContext, viewingContext } = useRelationalViewingContext(org);
  const error = errorViewingContext ?? errorOrg;
  const hasOrgAdminAccess = useHasOrgAdminAccess(org);
  const tabs = useOrgProfileTabs({ tabsConfig, orgType: org?.organisationType, viewingContext });
  const labels = tabs.map((tab) => getTabLabel(tab, t));
  const headerProps = useOrgProfileHeader({
    org,
    config: headerConfig,
  });
  const { referrer, currentTab, handleTabChange } = useOrgProfileTabNavigation(tabs);

  if (error) return <ErrorPanel subtitle={t('organisationsModule.queryErrors.getOrganisationProfile')} />;

  if (loading || !org || !viewingContext) return <Spinner fullScreen />;

  if (!org.organisationType || !allowedTypes.includes(org.organisationType)) return <Redirect to={ROUTES.NOT_FOUND} />;

  return (
    <PageMaxWidth>
      <Breadcrumbs
        paths={[
          {
            name: t(referrer.transKey),
            to: referrer.route,
            active: false,
            highlight: true,
          },
          {
            name: org.organisationName as string,
            active: true,
          },
        ]}
      />
      {headerProps && (
        <OrganisationProfileHeader {...headerProps} withAdminAccess={hasOrgAdminAccess} id={orgId as string} />
      )}
      <OrganisationProfileTabs
        tabs={tabs}
        currentTab={currentTab}
        handleTabChange={handleTabChange}
        labels={labels}
        orgId={orgId}
        orgName={org.organisationName as string}
      />
    </PageMaxWidth>
  );
};

export default OrganisationProfile;
