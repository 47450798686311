import React, { Fragment, ReactNode } from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/button/link';
import Icon from 'src/components/icon/icon';
import { OrgStatusLabel } from 'src/components/organisations/status-labels';
import Panel from 'src/components/panel/panel';
import { getEnvConfig } from 'src/config/config';
import { OrganisationStatusEnum } from 'src/graphql-types/globalSaasTypes';
import CircleDividerSvg from 'src/images/icons/16px/circle-divider.svg';

import * as styles from './organisation-profile-header.module.less';

export interface Props {
  id: string;
  name: string;
  status: OrganisationStatusEnum;
  address: string;
  extraInfo: { label: string; value: ReactNode }[];
  adminAccessBtnTransKey?: string;
  withAdminAccess?: boolean;
}

const OrganisationProfileHeader = ({
  id,
  name,
  status,
  address,
  extraInfo,
  adminAccessBtnTransKey = 'open organisation',
  withAdminAccess = false,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Panel classNames={{ container: styles.headerContainer, wrapper: styles.wrapper }}>
      <Grid>
        <h1 className={styles.heading}>
          {name} {status && <OrgStatusLabel status={status} />}
        </h1>
        <p className={styles.subheading}>{address}</p>
        <dl className={styles.infoList}>
          {extraInfo.map((info, index) => (
            <Fragment key={`${info.label}-${index}`}>
              {index > 0 && <CircleDividerSvg aria-hidden className={styles.separator} />}
              <dt>{info.label}:</dt> <dd>{info.value}</dd>
            </Fragment>
          ))}
        </dl>
      </Grid>
      {withAdminAccess && (
        <Grid>
          <Button
            level="tertiary"
            href={`${getEnvConfig().CLUBSPARK_CLASSIC_ADMIN_SITE}/Admin/vidr/${id}`}
            target="_blank"
            fluid
          >
            {t(adminAccessBtnTransKey)}
            <Icon name="sm-new-window" aria-hidden className={styles.externalLinkIcon} />
          </Button>
        </Grid>
      )}
    </Panel>
  );
};

export default OrganisationProfileHeader;
