import React from 'react';

import { Grid } from '@mui/material';
import { Tab } from 'src/components/organisations/organisation-profile/config';
import TabContent from 'src/components/tab-panel/tab-panel';
import Tabs from 'src/components/tabs/tabs';

import TabPanels from './tab-panels';

interface Props {
  tabs: Tab[];
  currentTab: number;
  labels: string[];
  handleTabChange: (event: any, newValue: number) => void;
  orgId?: string;
  orgName?: string;
}

const OrganisationProfileTabs = ({ tabs, currentTab, labels, handleTabChange, orgId, orgName }: Props) => {
  if (!tabs.length) return null;
  return (
    <>
      <Tabs value={currentTab} labels={labels} handleChange={handleTabChange} />
      {tabs.map((tab, i) => (
        <Grid key={`${labels[i]}-${i}`} item xs={12}>
          <TabContent value={currentTab} index={i} disableUnmount>
            <TabPanels orgId={orgId} orgName={orgName ?? undefined} panels={tab.panels} />
          </TabContent>
        </Grid>
      ))}
    </>
  );
};

export default OrganisationProfileTabs;
