// extracted by mini-css-extract-plugin
export var bodyBase = "organisation-profile-header-module--body-base--33d4e organisation-profile-header-module--site-font--a0778";
export var bodyLarge = "organisation-profile-header-module--body-large--c09a4 organisation-profile-header-module--body-base--33d4e organisation-profile-header-module--site-font--a0778";
export var bodyLargeBold = "organisation-profile-header-module--body-large-bold--48e13 organisation-profile-header-module--body-base--33d4e organisation-profile-header-module--site-font--a0778";
export var bodyRegular = "organisation-profile-header-module--body-regular--fcc0c organisation-profile-header-module--body-base--33d4e organisation-profile-header-module--site-font--a0778";
export var bodyRegularBold = "organisation-profile-header-module--body-regular-bold--921ee organisation-profile-header-module--body-base--33d4e organisation-profile-header-module--site-font--a0778";
export var bodySmall = "organisation-profile-header-module--body-small--d47f2 organisation-profile-header-module--body-base--33d4e organisation-profile-header-module--site-font--a0778";
export var bodySmallBold = "organisation-profile-header-module--body-small-bold--3ee15 organisation-profile-header-module--body-base--33d4e organisation-profile-header-module--site-font--a0778";
export var borderTop = "organisation-profile-header-module--border-top--e50a0";
export var breakWordContainer = "organisation-profile-header-module--break-word-container--9faa2";
export var buttonIconBase = "organisation-profile-header-module--button-icon-base--a1de2";
export var clickLink = "organisation-profile-header-module--click-link--aaa62";
export var dropdownBase = "organisation-profile-header-module--dropdown-base--75530";
export var dropdownSelectBase = "organisation-profile-header-module--dropdown-select-base--79cde organisation-profile-header-module--text-input--8edb7";
export var externalLinkIcon = "organisation-profile-header-module--externalLinkIcon--6aaf8";
export var flexCol = "organisation-profile-header-module--flex-col--1ccda";
export var formErrorMessage = "organisation-profile-header-module--form-error-message--f5730";
export var h3 = "organisation-profile-header-module--h3--cef1e";
export var h4 = "organisation-profile-header-module--h4--77ebf";
export var headerContainer = "organisation-profile-header-module--headerContainer--14968";
export var heading = "organisation-profile-header-module--heading--3a0b0";
export var hoverLink = "organisation-profile-header-module--hover-link--14f83";
export var hoverRow = "organisation-profile-header-module--hover-row--34ddc";
export var infoList = "organisation-profile-header-module--infoList--5059b";
export var membershipContainer = "organisation-profile-header-module--membership-container--bfce4 organisation-profile-header-module--flex-col--1ccda organisation-profile-header-module--primary-border--c37fc";
export var membershipHeader = "organisation-profile-header-module--membership-header--5c914";
export var membershipHeading = "organisation-profile-header-module--membership-heading--564ab";
export var membershipLabel = "organisation-profile-header-module--membership-label--3faf2";
export var moreFiltersBorderClass = "organisation-profile-header-module--more-filters-border-class--2cdec";
export var pageBg = "organisation-profile-header-module--page-bg--15e7d";
export var pointer = "organisation-profile-header-module--pointer--8ef3c";
export var primaryBorder = "organisation-profile-header-module--primary-border--c37fc";
export var separator = "organisation-profile-header-module--separator--4f137";
export var shadowBoxLight = "organisation-profile-header-module--shadow-box-light--13696";
export var siteFont = "organisation-profile-header-module--site-font--a0778";
export var slideDownAndFade = "organisation-profile-header-module--slideDownAndFade--52637";
export var slideLeftAndFade = "organisation-profile-header-module--slideLeftAndFade--b8e12";
export var slideRightAndFade = "organisation-profile-header-module--slideRightAndFade--286be";
export var slideUpAndFade = "organisation-profile-header-module--slideUpAndFade--f0f33";
export var statusDecoration = "organisation-profile-header-module--status-decoration--55831";
export var subheading = "organisation-profile-header-module--subheading--ceca4";
export var textInput = "organisation-profile-header-module--text-input--8edb7";
export var textInverted = "organisation-profile-header-module--text-inverted--f43e9";
export var textMediumDark = "organisation-profile-header-module--text-medium-dark--48c5e";
export var tooltipFont = "organisation-profile-header-module--tooltipFont--81d51";
export var unstyledButton = "organisation-profile-header-module--unstyled-button--dd503";
export var wrapper = "organisation-profile-header-module--wrapper--42363";