import React from 'react';

import { ORG_PROFILE_PANEL_MAP, TypedOrgPanel } from 'src/components/organisations/organisation-profile/config';

const TabPanels = ({ orgId, panels, orgName }: { orgId?: string; orgName?: string; panels: TypedOrgPanel[] }) => {
  return (
    <>
      {panels.map((panel, index) => {
        let Panel, props, key;
        if (typeof panel === 'string') {
          Panel = ORG_PROFILE_PANEL_MAP[panel];
          props = {};
          key = `${panel}-${index}`;
        } else {
          Panel = ORG_PROFILE_PANEL_MAP[panel.type];
          props = panel.props;
          key = `${panel.type}-${index}`;
        }

        return <Panel key={key} {...props} orgId={orgId} orgName={orgName} />;
      })}
    </>
  );
};

export default TabPanels;
